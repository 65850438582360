var candidateModule = (function(commonModule) {

    var datatableBaseURL = commonModule.datatableBaseURL + 'candidate';
    var datatableDistrictsBaseURL = commonModule.datatableBaseURL + 'districts';
    // var datatableCityBaseURL = commonModule.datatableBaseURL + 'city';

    var init = function() {
        _applyDatatable();
        _applyDatepicker();
        _setChangeStatus();

        $("#overlayForm").hide();

        if ($('#status').val()=='3'){
            $('#schedule-hired').hide('slow');
            $('#schedule-test').hide('slow');
            $('#schedule').show('slow');
        }
        else if ($('#status').val()=='1'){
            $('#schedule-hired').show('slow');
            $('#schedule').hide('slow');
            $('#schedule-test').hide('slow');
        }
        else if ($('#status').val()=='7'){
            $('#schedule-hired').hide('slow');
            $('#schedule').hide('slow');
            $('#schedule-test').show('slow');
        }
        else {
            $('#schedule').hide('slow');
            $('#schedule-hired').hide('slow');
            $('#schedule-test').hide('slow');
        }
    };

    var _applyDatepicker = function() {
        $('.datepicker').datepicker({
            weekStart: 1,
            todayHighlight: true,
            clearBtn: true,
            format: 'yyyy-mm-dd',
            autoclose: true
        });

        $('.datepicker-dmy').datepicker({
            weekStart: 1,
            todayHighlight: true,
            clearBtn: true,
            format: 'dd-mm-yyyy',
            autoclose: true
        });
    };

    var _setChangeStatus = function() {
        $('#status').change( function(){
            if ($('#status').val()=='3'){
                $('#schedule-hired').hide('slow');
                $('#schedule-test').hide('slow');
                $('#schedule').show('slow');
            }
            else if ($('#status').val()=='1'){
                $('#schedule-hired').show('slow');
                $('#schedule').hide('slow');
                $('#schedule-test').hide('slow');
            }
            else if ($('#status').val()=='7'){
                $('#schedule-hired').hide('slow');
                $('#schedule').hide('slow');
                $('#schedule-test').show('slow');
            }
            else {
                $('#schedule').hide('slow');
                $('#schedule-hired').hide('slow');
                $('#schedule-test').hide('slow');
            }
        });
    };

    var deleteCandidate = function(me) {
        swal({
                title: "Are you sure?",
                text: "You will not be able to recover this vacancy!",
                type: "warning",
                showCancelButton: true,
                confirmButtonColor: "#DD6B55",
                confirmButtonText: "Yes, delete it!",
                closeOnConfirm: false
            },
            function(isConfirm) {
                var candidateID = me.data('record-id');
                if (isConfirm) {
                    $.ajax({
                        method: "POST",
                        url: "/candidate/" + candidateID,
                        dataType: 'json',
                        data: {
                            '_method': 'DELETE',
                            '_token': $('meta[name="csrf-token"]').attr('content')
                        },
                        statusCode: {
                            422: function(xhr) {
                                swal({
                                    title: "Oops!",
                                    text: 'Failed form validation. Please check your input.',
                                    type: "error"
                                });
                            },
                            500: function(xhr) {
                                swal({
                                    title: "Oops!",
                                    text: 'Failed form validation. Please check your input.',
                                    type: "error"
                                });
                            }
                        }
                    }).done(function(response) {
                        console.log(response);
                        if (response.status == 1) {
                            swal({
                                title: "Good!",
                                text: response.message,
                                type: "success",
                                timer: 2000
                            }, function() {
                                me.closest('tr').remove();
                                $('#datatable').DataTable().ajax.reload(null, false);
                            });
                        } else {
                            swal({
                                title: "Oops!",
                                text: response.message,
                                type: "error",
                                timer: 2000
                            });
                        }
                    }).fail(function(response) {
                        swal({
                            title: "Oops!",
                            text: response.message,
                            type: "error",
                            timer: 2000
                        });
                    });
                }
            });
    };

    var _applyDatatable = function() {
        /* Tambah Input Field di TFOOT */
        $('#datatable tfoot th').each(function() {
            var title = $(this).text();
            if (title != '') {
                $(this).html('<input type="text" class="form-control" placeholder="Search ' + title + '" style="width: 100%;" />');
            }
            if (title == 'Created Date') {
                $(this).html('<input type="text" class="datepicker form-control" placeholder="Search ' + title + '" style="width: 100%;" />');
            }
            if (title == 'Date of Birth') {
                $(this).html('<input type="text" class="datepicker form-control" placeholder="Search ' + title + '" style="width: 100%;" />');
            }
            if (title == 'Updated Date') {
                $(this).html('<input type="text" class="datepicker form-control" placeholder="Search ' + title + '" style="width: 100%;" />');
            }
        });
        var table = $('#datatable').DataTable({
            processing: true,
            serverSide: true,
            ajax: {
                "url": datatableBaseURL,
                "type": "POST",
                "data": function (d) {
                    d.fullname = $('input[name=fullname]').val();
                    d.vacancyapply = $('select[name=vacancyapply]').val();
                    d.maritalstatus = $('select[name=maritalstatus]').val();
                    d.education = $('select[name=education]').val();
                    d.email = $('input[name=email]').val();
                    d.telephone = $('input[name=telephone]').val();
                    d.district = $('select[name=district]').val();
                    d.city = $('select[id=city]').val();
                    d.status = $('select[id=status_filter]').val();
                }
            },
            language: {
                "decimal": ",",
                "thousands": "."
            },
            order: [
                [16, "desc"]
            ],
            columnDefs: [ {         
                orderable: false,
                className: 'select-checkbox',
                searchable: false,
                targets:0
            } ],
            select: {
                style: 'os',
                selector: 'td:first-child'
            },
            lengthMenu: [[10, 25, 50, -1], [10, 25, 50, "All"]],
            bDestroy: true,
            columns: [{
                data: 'checkbox',
                name: 'checkbox',
            }, {
                data: 'action',
                name: 'action',
                orderable: false,
                searchable: false
            }, {
                data: 'full_name',
                name: 'full_name'
            }, {
                data: 'vacancy_name',
                name: 'vacancies.name'
            }, {
                data: 'marital_status',
                name: 'marital_status'
            }, {
                data: 'highest_education',
                name: 'highest_education'
            }, {
                data: 'email',
                name: 'email'
            }, {
                data: 'telephone',
                name: 'telephone'
            }, {
                data: 'date_of_birth',
                name: 'date_of_birth'
            }, {
                data: 'height',
                name: 'height'
            }, {
                data: 'district_name',
                name: 'd.region_name'
            }, {
                data: 'city_name',
                name: 'c.region_name'
            }, {
                data: 'status_name',
                name: 'candidates.status_id'
            },{
                data: 'hasil',
                name: 'hasil'
            },{
                data: 'keterangan',
                name: 'keterangan'
            }, {
                data: 'created_at',
                name: 'created_at'
            }, {
                data: 'updated_at',
                name: 'updated_at'
            }]
        });

        $(document).on('ifChanged', '#checkallbox', function(event) { 
            if(this.checked) {
                $('input[name=check_all]').prop('checked', true);
                $("th.select-checkbox").addClass("selected");
                $('#datatable').find('input[type="checkbox"]:checked').each(function () {
                    valuenya= $(this).val();
                    valuenya1= $(this).val().split("-");
                    candidate_vacancy_id= valuenya1[1];
                    $('#checkboxId-'+ candidate_vacancy_id).val(valuenya);
                });
            } else {
                $('#datatable').find('input[type="checkbox"]:checked').each(function () {
                    valuenya1= $(this).val().split("-");
                    candidate_vacancy_id= valuenya1[1];
                    $('#checkboxId-'+ candidate_vacancy_id).val('');
                });               
                $('input[name=check_all]').prop('checked', false);
                $("th.select-checkbox").removeClass("selected");
            }
        });

        $('#btn-modal-save').on("click", function () {
        // $('#btn-modal-save').click(function () {
            var candidate_id = $("#modal-input-id").val();
            var candidate_desc_asal= $("#modal-input-description-asal").val();
            var candidate_desc = $("#modal-input-description").val();
            var _token = $('input[name="_token"]').val();

            if ((candidate_desc != '') && (candidate_desc_asal != candidate_desc) )
            {
                $.ajax({
                    dataType: 'json',
                    type:'POST',
                    // url: "{{ route('candidate.updatedesc') }}",
                    url: "candidate.updatedesc",
                    data: { _token:_token,candidate_id:candidate_id,candidate_desc:candidate_desc},
                    success: function(data) {
                        swal('Updated',data.message,'success');
                        candidateModule.init();
                    },
                      error: function(data) {
                        swal('Warning',data.status,'error');
                    }
                });
            }
        });      
        
        $('#btnsave_hasil').on("click", function () {
        // $('#btnsave_hasil').click(function () {
            var candidate_id=[];
            var hasil = $("#opt_hasil").val();
            var valuenya="";
            var total=$('#datatable').find('input[type="checkbox"]:checked').length;
            var _token = $('input[name="_token"]').val();

            if (total > 0 )
            {
                $('#datatable').find('input[name="check_all"]:checked').each(function () {
                    valuenya1= $(this).val().split("-");
                    candidate_id.push(valuenya1[2]);
                });

                $.ajax({
                    dataType: 'json',
                    type:'POST',
                    // url: "{{ route('candidate.updatehasil') }}",
                    url: "candidate.updatehasil",
                    data: { _token:_token,hasil:hasil,candidate_id:candidate_id},
                    success: function(data) {
                        swal('Updated',data.message,'success');
                        candidateModule.init();
                    },
                      error: function(data) {
                        swal('Warning',data.status,'error');
                    }
                });
            }
        });      

        $('#btnFilter').on("click", function (e) {
            table.draw();
            e.preventDefault();
            console.log("filter candidate");
        });


        var keyword;
        /* Ketika Value pada Input di TFOOT berubah, Maka Search Sesuai Kolom */
        table.columns().every(function() {
            var that = this;
            $('input', this.footer()).on('keyup change', _.debounce(function() {
                keyword = this.value;

                // Ganti ke Huruf Besar Semua */
                keyword = keyword.toUpperCase();

                if (this.placeholder == 'Search Marital Status') {
                    if (keyword == 'MENIKAH' || keyword == 'SUDAH' || keyword == 'SUDAH MENIKAH') {
                        keyword = 1;
                    } else {
                        keyword = 0;
                    }
                }

                if (this.placeholder == 'Search Status') {
                    if (keyword == 'NEW APPLICANT' || keyword == 'NEW' || keyword == 'APPLICANT') {
                        keyword = 6;
                    }
                    else if(keyword == 'PSYCHOLOGICAL TEST SCHEDULED' || keyword == 'PSYCHOLOGICAL' || keyword == 'TEST' || keyword == 'SCHEDULED') {
                        keyword = 7;
                    }
                    else if(keyword == 'INTERVIEW SCHEDULED' || keyword == 'INTERVIEW' || keyword == 'SCHEDULED') {
                        keyword = 3;
                    }
                    else if(keyword == 'INTERVIEW PASSED' || keyword == 'INTERVIEW' || keyword == 'PASSED') {
                        keyword = 4;
                    }
                    else if(keyword == 'INTERVIEW FAILED' || keyword == 'INTERVIEW' || keyword == 'FAILED') {
                        keyword = 5;
                    }
                    else if(keyword == 'REJECTED') {
                        keyword = 2;
                    }
                    else if(keyword == 'HIRED') {
                        keyword = 1;
                    }
                    else if (keyword == '' || keyword == undefined) {
                        keyword = '';
                    }
                }

                if (this.placeholder == 'Search Age') {
                    var age = keyword;
                    if (keyword == '' || keyword == undefined) {
                        keyword = '';
                    }
                    else{
                        keyword = new Date().getFullYear() - age;
                    }
                }

                if (this.placeholder == 'Search Is Matched') {
                    if (keyword == 'YES' || keyword == 'YA') {
                        keyword = 1;
                    } else {
                        keyword = 0;
                    }
                }

                if (keyword !== '' || keyword !== undefined) {
                    if (that.search() !== keyword) {
                        that
                            .search(keyword)
                            .draw();
                    }
                }
            }, 500));
        });
    };

    var updateStatus = function() {
        var candidateVacancyID = $("input[name=candidate_vacancy_id]").val();
        /* Show Overlay */
        $("#overlayForm").show();

        /* Send Data */
        $.ajax({
            method: "PUT",
            url: "/candidate/" + candidateVacancyID,
            data: $('#main_form').serialize(),
            dataType: 'json',
            statusCode: {
                422: function(xhr) {
                    swal({
                        title: "Oops!",
                        text: 'Failed form validation. Please check your input.',
                        type: "error"
                    });
                },
                500: function(xhr) {
                    swal({
                        title: "Oops!",
                        text: 'Something went wrong.',
                        type: "error"
                    });
                    $("#overlayForm").hide();
                }
            }
        }).done(function(response) {
            if (response.status == 1) {
                swal({
                    title: "Good!",
                    text: response.message,
                    type: "success",
                    timer: 2000
                }, function() {
                    //
                });
            } else {
                swal({
                    title: "Oops!",
                    text: response.message,
                    type: "error",
                    timer: 2000
                });
            }
            /* Hide Overlay */
            $("#overlayForm").hide();
        });
    };

    //di remark karena sudah ada di masing2 blade
    //fetch data for district dropdown based on city
    // $('.dynamic_select').change(function(){
    //     var dependent = $(this).data('dependent');
    //     if($(this).val()!='')
    //     {
    //         var value = $(this).val();
    //         var _token = $('input[name="_token"]').val();
    //         $.ajax({
    //             // url:"{{route('city_dynamic_dependent.fetch')}}",
    //             url:"city/fetch",
    //             method:"POST",
    //             data:{value:value,_token:_token,dependent:dependent},
    //             success:function(result)
    //             {
    //                 $('#'+dependent).html(result);
    //             }
    //         })
    //     }else{
    //         $('#'+dependent).empty().append('<option value="">Choose District</option>');
    //     }
    // });

    return {
        init: init,
        deleteCandidate: deleteCandidate,
        updateStatus: updateStatus
    };

})(commonModule);