var employeeModule = (function (commonModule) {

    var datatableBaseURL = commonModule.datatableBaseURL + 'employee';

    var init = function () {
        _applyDatatable();
        _applyDatepicker();
        $("#overlayForm").hide();
    };

    var _applyDatepicker = function () {
        $('.datepicker').datepicker({
            weekStart: 1,
            todayHighlight: true,
            clearBtn: true,
            format: 'yyyy-mm-dd',
            autoclose: true
        });
    };

    var deleteEmployee = function (me) {
        swal({
                title: "Are you sure?",
                text: "You will not be able to recover this data!",
                type: "warning",
                showCancelButton: true,
                confirmButtonColor: "#DD6B55",
                confirmButtonText: "Yes, delete it!",
                closeOnConfirm: false
            },
            function (isConfirm) {
                var employeeID = me.data('record-id');
                if (isConfirm) {
                    $.ajax({
                        method: "POST",
                        url: "/employee/" + employeeID,
                        dataType: 'json',
                        data: {
                            '_method': 'DELETE',
                            '_token': $('meta[name="csrf-token"]').attr('content')
                        },
                        statusCode: {
                            422: function (xhr) {
                                swal({
                                    title: "Oops!",
                                    text: 'Failed form validation. Please check your input.',
                                    type: "error"
                                });
                            },
                            500: function (xhr) {
                                swal({
                                    title: "Oops!",
                                    text: 'Failed form validation. Please check your input.',
                                    type: "error"
                                });
                            }
                        }
                    }).done(function (response) {
                        console.log(response);
                        if (response.status == 1) {
                            swal({
                                title: "Good!",
                                text: response.message,
                                type: "success",
                                timer: 2000
                            }, function () {
                                me.closest('tr').remove();
                                $('#datatable').DataTable().ajax.reload(null, false);
                            });
                        } else {
                            swal({
                                title: "Oops!",
                                text: response.message,
                                type: "error",
                                timer: 2000
                            });
                        }
                    }).fail(function (response) {
                        swal({
                            title: "Oops!",
                            text: response.message,
                            type: "error",
                            timer: 2000
                        });
                    });
                }
            });
    };

    var _applyDatatable = function () {
        /* Tambah Input Field di TFOOT */
        $('#datatable tfoot th').each(function () {
            var title = $(this).text();
            if (title != '') {
                $(this).html('<input type="text" class="form-control" placeholder="Search ' + title + '" style="width: 100%;" />');
            }
            if (title == 'Created Date') {
                $(this).html('<input type="text" class="datepicker form-control" placeholder="Search ' + title + '" style="width: 100%;" />');
            }
            if (title == 'Date of Birth') {
                $(this).html('<input type="text" class="datepicker form-control" placeholder="Search ' + title + '" style="width: 100%;" />');
            }
            if (title == 'Updated Date') {
                $(this).html('<input type="text" class="datepicker form-control" placeholder="Search ' + title + '" style="width: 100%;" />');
            }
        });

        var table = $('#datatable').DataTable({
            processing: true,
            serverSide: true,
            ajax: {
                "url": datatableBaseURL,
                "type": "POST",
                "data": function (d) {
                    d.fullname = $('input[name=fullname]').val();
                    d.education = $('select[name=education]').val();
                    d.email = $('input[name=email]').val();
                    d.telephone = $('input[name=telephone]').val();
                    d.district = $('select[name=district]').val();
                    d.city = $('select[name=city_id]').val();
                }
            },
            language: {
                "decimal": ",",
                "thousands": "."
            },
            order: [[12, "desc"]],
            columnDefs: [ {
                orderable: false,
                className: 'select-checkbox',
                searchable: false,
                targets:0
            } ],
            select: {
                style: 'os',
                selector: 'td:first-child'
            },
            lengthMenu: [[10, 25, 50, -1], [10, 25, 50, "All"]],
            bDestroy: true,
            columns: [{
                data: 'checkbox',
                name: 'checkbox'
            },{
                data: 'action',
                name: 'action',
                orderable: false,
                searchable: false
            }, {
                data: 'full_name',
                name: 'full_name'
            }, {
                data: 'major',
                name: 'major'
            }, {
                data: 'highest_education',
                name: 'highest_education' 
            }, {
                data: 'email',
                name: 'email'
            }, {
                data: 'telephone',
                name: 'telephone'
            }, {
                data: 'date_of_birth',
                name: 'date_of_birth'
            }, {
                data: 'district_name',
                name: 'd.region_name'
            }, {
                data: 'city_name',
                name: 'c.region_name'
            }, {
                data: 'keterangan',
                name: 'keterangan'
            },{
                data: 'created_at',
                name: 'created_at'
            }, {
                data: 'updated_at',
                name: 'updated_at'
            }]
        });

        $(document).on('ifChanged', '#checkallbox', function(event) {
            if(this.checked) {
                $('input[name=check_all]').prop('checked', true);
                $("th.select-checkbox").addClass("selected");
                $('#datatable').find('input[type="checkbox"]:checked').each(function () {
                    valuenya= $(this).val();
                    valuenya1= $(this).val().split("-");
                    candidate_vacancy_id= valuenya1[1];
                    $('#checkboxId-'+ candidate_vacancy_id).val(valuenya);
                });
            } else {
                $('#datatable').find('input[type="checkbox"]:checked').each(function () {
                    valuenya1= $(this).val().split("-");
                    candidate_vacancy_id= valuenya1[1];
                    $('#checkboxId-'+ candidate_vacancy_id).val('');
                });
                $('input[name=check_all]').prop('checked', false);
                $("th.select-checkbox").removeClass("selected");
            }
        });

        $('#btn-modal-save').on("click", function () {
            // $('#btn-modal-save').click(function () {
            var employee_id = $("#modal-input-id").val();
            var employee_desc_asal= $("#modal-input-description-asal").val();
            var employee_desc = $("#modal-input-description").val();
            var _token = $('input[name="_token"]').val();

            if ((employee_desc != '') && (employee_desc_asal != employee_desc) )
            {
                $.ajax({
                    dataType: 'json',
                    type:'POST',
                    // url: "{{ route('candidate.updatedesc') }}",
                    url: "employee.updatedesc",
                    data: { _token:_token,employee_id:employee_id,employee_desc:employee_desc},
                    success: function(data) {
                        swal('Updated',data.message,'success');
                        employeeModule.init();
                    },
                    error: function(data) {
                        swal('Warning',data.status,'error');
                    }
                });
            }
        });


        $('#btnFilter').on("click", function (e) {
            table.draw();
            e.preventDefault();
            console.log("filter employee");
        });

        /* Ketika Value pada Input di TFOOT berubah, Maka Search Sesuai Kolom */
        table.columns().every(function () {
            var that = this;
            $('input', this.footer()).on('keyup change', _.debounce(function () {
                var keyword = this.value;

                // Ganti ke Huruf Besar Semua */
                keyword = keyword.toUpperCase();

                if (this.placeholder == 'Search Marital Status') {
                    if (keyword == 'MENIKAH') {
                        keyword = 1;
                    } else {
                        keyword = 0;
                    }
                }

                if (this.placeholder == 'Search Age') {
                    var age = keyword;
                    if (keyword == '' || keyword == undefined) {
                        keyword = '';
                    }
                    else{
                        keyword = new Date().getFullYear() - age;
                    }
                }

                if (keyword !== '' || keyword !== undefined) {
                    if (that.search() !== keyword) {
                        that
                            .search(keyword)
                            .draw();
                    }
                }
            }, 500));
        });
    };

    return {
        init: init,
        deleteEmployee: deleteEmployee
    };

})(commonModule);