var commonModule = (function() {

    var datatableBaseURL = '/datatable/';
    var select2BaseURL = '/select2/';
    var locationServiceURL = 'https://location-api.yogyagroup.com/api/';

    return {
        datatableBaseURL: datatableBaseURL,
        select2BaseURL: select2BaseURL,
        locationServiceURL: locationServiceURL
    };

})();