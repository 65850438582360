var bannerModule = (function(commonModule) {

    var datatableBaseURL = commonModule.datatableBaseURL + 'banner';

    var init = function() {
        _applyDatatable();
        _applyWYSIWYG();
        _applyDatepicker();
        $("#overlayForm").hide();
        _applyAJAXSetup();
    };

    var _applyAJAXSetup = function() {

    };

    var _applyDatepicker = function() {
        $('.datepicker').datepicker({
            weekStart: 1,
            todayHighlight: true,
            clearBtn: true,
            format: 'yyyy-mm-dd',
            autoclose: true
        });
    };

    var _applyWYSIWYG = function() {
        $("#editor").summernote();
    };

    var addRow = function() {
        var row_table = $("table#row_table");
        var template_row = $("#new_row").find('tbody');
        var new_row = template_row.clone();
        new_row = new_row.html();
        row_table.find('tbody').append(new_row);
    };

    var removeRow = function(me) {
        me.closest('tr').remove();
    };

    var createBanner = function() {

        var formData = new FormData($("form#main_form")[0]);

        /* Show Overlay */
        $("#overlayForm").show();

        /* Send Data */
        $.ajax({
            method: "POST",
            url: "/banner",
            data: formData,
            enctype: 'multipart/form-data',
            contentType: false,
            processData: false,
            async: false,
            dataType: 'json',
            statusCode: {
                422: function(xhr) {
                    swal({
                        title: "Oops!",
                        text: 'Failed form validation. Please check your input.',
                        type: "error"
                    });
                    $("#overlayForm").hide();
                },
                500: function(xhr) {
                    swal({
                        title: "Oops!",
                        text: 'Something went wrong.',
                        type: "error"
                    });
                    $("#overlayForm").hide();
                }
            }
        }).done(function(response) {
            if (response.status == 1) {
                swal({
                    title: "Good!",
                    text: response.message,
                    type: "success",
                    timer: 2000
                }, function() {

                    $("#main_form").trigger('reset');

                });
            } else {
                swal({
                    title: "Oops!",
                    text: response.message,
                    type: "error",
                    timer: 2000
                });
            }
            /* Hide Overlay */
            $("#overlayForm").hide();
        });
    };

    var updateBanner = function() {
        var bannerID = $("input[name=banner_id]").val();
        /* Show Overlay */
        $("#overlayForm").show();

        var formData = new FormData($("form#main_form")[0]);

        /* Send Data */
        $.ajax({
            method: "POST",
            url: "/banner/" + bannerID,
            data: formData,
            enctype: 'multipart/form-data',
            contentType: false,
            processData: false,
            async: false,
            dataType: 'json',
            statusCode: {
                422: function(xhr) {
                    swal({
                        title: "Oops!",
                        text: 'Failed form validation. Please check your input.',
                        type: "error"
                    });
                },
                500: function(xhr) {
                    swal({
                        title: "Oops!",
                        text: 'Something went wrong.',
                        type: "error"
                    });
                    $("#overlayForm").hide();
                }
            }
        }).done(function(response) {
            if (response.status == 1) {
                swal({
                    title: "Good!",
                    text: response.message,
                    type: "success",
                    timer: 2000
                }, function() {
                    location.reload();
                    $("#main_form").trigger('reset');
                });
            } else {
                swal({
                    title: "Oops!",
                    text: response.message,
                    type: "error",
                    timer: 2000
                });
            }
            /* Hide Overlay */
            $("#overlayForm").hide();
        });
    };

    var deleteBanner = function(me) {
        swal({
                title: "Are you sure?",
                text: "You will not be able to recover this banner!",
                type: "warning",
                showCancelButton: true,
                confirmButtonColor: "#DD6B55",
                confirmButtonText: "Yes, delete it!",
                closeOnConfirm: false
            },
            function(isConfirm) {
                var bannerID = me.data('record-id');
                if (isConfirm) {
                    $.ajax({
                        method: "POST",
                        url: "/banner/" + bannerID,
                        dataType: 'json',
                        data: {
                            '_method': 'DELETE',
                            '_token': $('meta[name="csrf-token"]').attr('content')
                        },
                        statusCode: {
                            422: function(xhr) {
                                swal({
                                    title: "Oops!",
                                    text: 'Failed form validation. Please check your input.',
                                    type: "error"
                                });
                            },
                            500: function(xhr) {
                                swal({
                                    title: "Oops!",
                                    text: 'Failed form validation. Please check your input.',
                                    type: "error"
                                });
                            }
                        }
                    }).done(function(response) {
                        console.log(response);
                        if (response.status == 1) {
                            swal({
                                title: "Good!",
                                text: response.message,
                                type: "success",
                                timer: 2000
                            }, function() {
                                me.closest('tr').remove();
                                $('#datatable').DataTable().ajax.reload(null, false);
                            });
                        } else {
                            swal({
                                title: "Oops!",
                                text: response.message,
                                type: "error",
                                timer: 2000
                            });
                        }
                    }).fail(function(response) {
                        swal({
                            title: "Oops!",
                            text: response.message,
                            type: "error",
                            timer: 2000
                        });
                    });
                }
            });

    };

    var _applyDatatable = function() {
        /* Tambah Input Field di TFOOT */
        $('#datatable tfoot th').each(function() {
            var title = $(this).text();
            if (title !== '') {
                $(this).html('<input type="text" class="form-control" placeholder="Search ' + title + '" style="width: 100%;" />');
            }
            if (title == 'Created Date') {
                $(this).html('<input type="text" class="datepicker form-control" placeholder="Search ' + title + '" style="width: 100%;" />');
            }
            if (title == 'Updated Date') {
                $(this).html('<input type="text" class="datepicker form-control" placeholder="Search ' + title + '" style="width: 100%;" />');
            }
        });

        var table = $('#datatable').DataTable({
            processing: true,
            serverSide: true,
            ajax: {
                "url": datatableBaseURL,
                "type": "POST"
            },
            language: {
                "decimal": ",",
                "thousands": "."
            },
            order: [
                [4, "desc"]
            ],
            columns: [{
                data: 'action',
                name: 'action',
                orderable: false,
                searchable: false
            }, {
                data: 'name',
                name: 'name'
            }, {
                data: 'path',
                name: 'path'
            }, {
                data: 'url',
                name: 'url'
            }, {
                data: 'created_at',
                name: 'created_at'
            }, {
                data: 'updated_at',
                name: 'updated_at'
            }]
        });

        /* Ketika Value pada Input di TFOOT berubah, Maka Search Sesuai Kolom */
        table.columns().every(function() {
            var that = this;
            $('input', this.footer()).on('keyup change', _.debounce(function() {

                var keyword = this.value;

                // Ganti ke Huruf Besar Semua */
                keyword = keyword.toUpperCase();

                if (that.search() !== this.value) {
                    that
                        .search(this.value)
                        .draw();
                }
            }, 500));
        });
    };

    return {
        init: init,
        addRow: addRow,
        removeRow: removeRow,
        createBanner: createBanner,
        updateBanner: updateBanner,
        deleteBanner: deleteBanner
    };

})(commonModule);