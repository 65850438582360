var fronteModule = (function() {

    var init = function() {
        _applyDatepicker_1();
    };

    var _applyDatepicker_1 = function() {
        var d = new Date();
        var minsyear= d.getFullYear()-16;
        var maxsyear= d.getFullYear()-50;
        yearrange=maxsyear+':'+minsyear;

        $('#date_of_birth').datepicker({
            changeMonth: true,
            changeYear: true,
            showOtherMonths: true,
            selectOtherMonths: true,
            dateFormat: "dd-mm-yy",
            yearRange: yearrange
        });
    };

    $('#kontakada').on("click", function () {
        var val = $('#kontakada').val();
        $('.informationContants').show();
        $('input[name=name_contacts]').attr('required', true);
        $('input[name=location_job_contacts]').attr('required',true);
        $('input[name=name_contacts]').val('');
        $('input[name=location_job_contacts]').val('');
    });

    $('#kontaktidak').on("click", function () {
        var val = $('#kontaktidak').val();
        $('.informationContants').hide();
        $('input[name=name_contacts]').attr('required', false);
        $('input[name=location_job_contacts]').attr('required', false);
        $('input[name=name_contacts]').val('');
        $('input[name=location_job_contacts]').val('');
    });

    $('#suku_bangsa').change(function(){ 
        var sukubangsa=$(this).val();
        if (sukubangsa !='Others'){
          $('.isi_sukubangsa').hide();
          $('#suku_bangsa_other').val('');
        }else{
          $('.isi_sukubangsa').show();
          $('#suku_bangsa_other').val('');
          $('#suku_bangsa_other').focus();   
        }
    });

    $("#konfirmasi").change(function() {
        if(this.checked) {
            $('#btn_submit').removeAttr('disabled');
        }else{
            $('#btn_submit').attr('disabled', 'disabled');
        }
    });

    // var validate_fileupload = function(fileName,object,extention) {
    // // function validate_fileupload(fileName,object,extention){
    //     extention =='image/*' ? extention =".jpg,.png,.gif,.jpeg" : extention;
    //     var allowed_extentions = extention.split(",");
    //     var file_extension = '.' + fileName.split('.').pop().toLowerCase(); 
    //     var file_size = $('#'+object)[0].files[0].size;
    //     var validation_alert="";
    
    //     if (file_size>2097152){
    //       validation_alert += "File maksimum 2 MB";
    //     }else{
    //       if (jQuery.inArray(file_extension, allowed_extentions )==-1){
    //         validation_alert += "File yang di perbolehkan:" + extention;
    //       }
    //     }
    //     if (validation_alert !=""){
    //         $("#"+object).val("");
    //         alert(validation_alert)
    //     }
    // }

    return {
        init: init
    };

})(commonModule);