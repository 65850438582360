var vacancyModule = (function(commonModule) {
    var cloneCount = 1;
    var datatableBaseURL = commonModule.datatableBaseURL + 'vacancy';
    var datatableVacancyCandidateBaseURL = commonModule.datatableBaseURL + 'candidate-vacancy';

    var init = function() {
        _applyDatatable();
        _applyDatatableVacancyCandidate();
        _applyDatepicker();
        _applySelect2();
        $("#overlayForm").hide();
    };

    var _applySelect2 = function() {
        $('.select2').select2();
    };

    var _populateDistricts = function() {
        $('#main_form .districts').each(function() {
            $(this).select2({
                delay: 250,
                minimumInputLength: 2,
                ajax: {
                    url: commonModule.locationServiceURL + 'districts',
                    type: 'GET',
                    dataType: 'json',
                    data: function(params) {
                        var keyword = params.term;
                        return {
                            'name-lk': '*' + keyword.toUpperCase() + '*'
                        };
                    },
                    processResults: function(data) {
                        return {
                            results: $.map(data, function(item) {
                                return {
                                    text: item.name,
                                    id: item.id
                                };
                            })
                        };
                    }

                }

            })
        });
    };

    var _applyDatepicker = function() {
        $('.datepicker').datepicker({
            weekStart: 1,
            todayHighlight: false,
            clearBtn: true,
            format: 'yyyy-mm-dd',
            autoclose: true
        });
    };

    var addRow = function() {
        if($("#last_row_number").length){
            var last_number_add=parseInt($("#last_row_number").val());
        }else{
            last_number_add=cloneCount;
        }

        var row_table = $("table#row_table");
        var template_row = $("#new_row").find('tbody');
        var new_row = template_row.clone();
        new_row = new_row.html();
        row_table.find('tbody').append(new_row);
        urutan=last_number_add+1;
        row_table.find('#location_row').val(urutan);
        row_table.find('#location_row').attr('id', 'location_row_'+ urutan);
        row_table.find('#cities_new').attr('data-dependent', 'districts'+ urutan);
        row_table.find('#cities_new').attr('name', 'location_cities_'+ urutan);
        row_table.find('#cities_new').attr('id', 'cities'+ urutan);
        row_table.find('#districts_new').attr('name', 'location_districts_'+ urutan +'[]');
        row_table.find('#districts_new').attr('id', 'districts'+ urutan);
        row_table.find('#expired_new').attr('name', 'location_expired_dates_'+ urutan);
        row_table.find('#expired_new').attr('id', 'expired'+ urutan);
        row_table.find('#addrow').attr('id', 'row_'+ urutan);
        last_number_add=urutan
        if($("#last_row_number").length){
            $("#last_row_number").val(urutan);
        }
        cloneCount++;

        _applyDatepicker();
        // _populateDistricts();
    };

    var removeRow = function(me) {
        me.closest('tr').remove();
    };

    var createVacancy = function() {
        /* Show Overlay */
        $("#overlayForm").show();

        /* Send Data */
        $.ajax({
            method: "POST",
            url: "/vacancy",
            data: $('#main_form').serialize(),
            dataType: 'json',
            statusCode: {
                422: function(xhr) {
                    swal({
                        title: "Oops!",
                        text: 'Failed form validation. Please check your input.',
                        type: "error"
                    });
                    $("#overlayForm").hide();
                },
                500: function(xhr) {
                    swal({
                        title: "Oops!",
                        text: 'Something went wrong.',
                        type: "error"
                    });
                    $("#overlayForm").hide();
                }
            }
        }).done(function(response) {
            if (response.status == 1) {
                swal({
                    title: "Good!",
                    text: response.message,
                    type: "success",
                    timer: 2000
                }, function() {
                    $('.districts')
                        .find('option')
                        .remove()
                        .end()
                        .append('<option value="whatever">All District</option>')
                        .val('all')
                    ;
                    $("#main_form").trigger('reset');
                });
            } else {
                swal({
                    title: "Oops!",
                    text: response.message,
                    type: "error",
                    timer: 2000
                });
            }
            /* Hide Overlay */
            $("#overlayForm").hide();
        });
    };

    var updateVacancy = function() {
        var vacancyID = $("input[name=vacancy_id]").val();
        /* Show Overlay */
        $("#overlayForm").show();

        /* Send Data */
        $.ajax({
            method: "PUT",
            url: "/vacancy/" + vacancyID,
            data: $('#main_form').serialize(),
            dataType: 'json',
            statusCode: {
                422: function(xhr) {
                    swal({
                        title: "Oops!",
                        text: 'Failed form validation. Please check your input.',
                        type: "error"
                    });
                },
                500: function(xhr) {
                    swal({
                        title: "Oops!",
                        text: 'Something went wrong.',
                        type: "error"
                    });
                    $("#overlayForm").hide();
                }
            }
        }).done(function(response) {
            if (response.status == 1) {
                swal({
                    title: "Good!",
                    text: response.message,
                    type: "success",
                    timer: 2000
                }, function() {
                    //
                });
            } else {
                swal({
                    title: "Oops!",
                    text: response.message,
                    type: "error",
                    timer: 2000
                });
            }
            /* Hide Overlay */
            $("#overlayForm").hide();
        });
    };

    var deleteVacancy = function(me) {
        swal({
                title: "Are you sure?",
                text: "You will not be able to recover this vacancy!",
                type: "warning",
                showCancelButton: true,
                confirmButtonColor: "#DD6B55",
                confirmButtonText: "Yes, delete it!",
                closeOnConfirm: false
            },
            function(isConfirm) {
                var vacancyID = me.data('record-id');
                if (isConfirm) {
                    $.ajax({
                        method: "POST",
                        url: "/vacancy/" + vacancyID,
                        dataType: 'json',
                        data: {
                            '_method': 'DELETE',
                            '_token': $('meta[name="csrf-token"]').attr('content')
                        },
                        statusCode: {
                            422: function(xhr) {
                                swal({
                                    title: "Oops!",
                                    text: 'Failed form validation. Please check your input.',
                                    type: "error"
                                });
                            },
                            500: function(xhr) {
                                swal({
                                    title: "Oops!",
                                    text: 'Failed form validation. Please check your input.',
                                    type: "error"
                                });
                            }
                        }
                    }).done(function(response) {
                        console.log(response);
                        if (response.status == 1) {
                            swal({
                                title: "Good!",
                                text: response.message,
                                type: "success",
                                timer: 2000
                            }, function() {
                                me.closest('tr').remove();
                                $('#datatable').DataTable().ajax.reload(null, false);
                            });
                        } else {
                            swal({
                                title: "Oops!",
                                text: response.message,
                                type: "error",
                                timer: 2000
                            });
                        }
                    }).fail(function(response) {
                        swal({
                            title: "Oops!",
                            text: response.message,
                            type: "error",
                            timer: 2000
                        });
                    });
                }
            });
    };

    var _applyDatatable = function() {
        /* Tambah Input Field di TFOOT */
        $('#datatable tfoot th').each(function() {
            var title = $(this).text();
            if (title != '') {
                $(this).html('<input type="text" class="form-control" placeholder="Search ' + title + '" style="width: 100%;" />');
            }
            if (title == 'Created Date') {
                $(this).html('<input type="text" class="datepicker form-control" placeholder="Search ' + title + '" style="width: 100%;" />');
            }
            if (title == 'Updated Date') {
                $(this).html('<input type="text" class="datepicker form-control" placeholder="Search ' + title + '" style="width: 100%;" />');
            }
        });

        var table = $('#datatable').DataTable({
            processing: true,
            serverSide: true,
            ajax: {
                "url": datatableBaseURL,
                "type": "POST"
            },
            language: {
                "decimal": ",",
                "thousands": "."
            },
            order: [
                [4, "desc"]
            ],
            columns: [{
                data: 'action',
                name: 'action',
                orderable: false,
                searchable: false
            }, {
                data: 'name',
                name: 'name'
            }, {
                data: 'requirement_set_id',
                name: 'requirement_set_id'
            }, {
                data: 'is_published',
                name: 'is_published'
            }, {
                data: 'created_at',
                name: 'created_at'
            }, {
                data: 'updated_at',
                name: 'updated_at'
            }]
        });

        /* Ketika Value pada Input di TFOOT berubah, Maka Search Sesuai Kolom */
        table.columns().every(function() {
            var that = this;
            $('input', this.footer()).on('keyup change', function() {
                var keyword = this.value;

                if (this.placeholder == 'Search Published') {
                    keyword = keyword.toUpperCase();
                    if (keyword == 'TRUE' || keyword == 'YA' || keyword == 'YES' || keyword == 'Y' || keyword == '1') {
                        keyword = 1;
                    } else {
                        keyword = 0;
                    }
                }

                if (this.placeholder == 'Search Requirement Set') {
                    $.ajax({
                        method: "GET",
                        url: "/api/requirement-set?name-lk=" + keyword,
                        dataType: 'json',
                    }).done(function(response) {
                        if (response.status == 1) {
                            keyword = response.id;
                        } else {
                            keyword = 0;
                        }

                        if (that.search() !== keyword) {
                            that
                                .search(keyword)
                                .draw();
                        }
                    });
                };

                if (keyword !== '' || keyword !== undefined) {
                    if (that.search() !== keyword) {
                        that
                            .search(keyword)
                            .draw();
                    }
                }

            });
        });

    };

    var _applyDatatableVacancyCandidate = function() {
        /* Get Vacancy ID from URL Segment */
        var url = $(location).attr('href');
        var segments = url.split('/');
        var vacancyID = segments[4];

        /* Tambah Input Field di TFOOT */
        $('#datatable_vacancy_candidate tfoot th').each(function() {
            var title = $(this).text();
            if (title != '') {
                $(this).html('<input type="text" class="form-control" placeholder="Search ' + title + '" style="width: 100%;" />');
            }
            if (title == 'Created Date') {
                $(this).html('<input type="text" class="datepicker form-control" placeholder="Search ' + title + '" style="width: 100%;" />');
            }
            if (title == 'Updated Date') {
                $(this).html('<input type="text" class="datepicker form-control" placeholder="Search ' + title + '" style="width: 100%;" />');
            }
        });

        var table = $('#datatable_vacancy_candidate').DataTable({
            processing: true,
            serverSide: true,
            ajax: {
                "url": datatableVacancyCandidateBaseURL + '/' + vacancyID,
                "type": "POST"
            },
            language: {
                "decimal": ",",
                "thousands": "."
            },
            columns: [{
                data: 'action',
                name: 'action',
                orderable: false,
                searchable: false
            }, {
                data: 'status',
                name: 'candidate_vacancies.status'
            }, {
                data: 'is_matched',
                name: 'candidate_vacancies.is_matched'
            }, {
                data: 'full_name',
                name: 'candidates.full_name'
            }, {
                data: 'marital_status',
                name: 'candidates.marital_status'
            }, {
                data: 'highest_education',
                name: 'candidates.highest_education'
            }, {
                data: 'telephone',
                name: 'candidates.telephone'
            }, {
                data: 'date_of_birth',
                name: 'candidate_vacancies.date_of_birth'
            }, {
                data: 'district_name',
                name: 'district_name'
            }, {
                data: 'city_name',
                name: 'city_name'
            }, {
                data: 'created_at',
                name: 'candidate_vacancies.created_at'
            }, {
                data: 'updated_at',
                name: 'candidate_vacancies.updated_at'
            }]
        });

        /* Ketika Value pada Input di TFOOT berubah, Maka Search Sesuai Kolom */
        table.columns().every(function() {
            var that = this;
            $('input', this.footer()).on('keyup change', _.debounce(function() {
                var keyword = this.value;

                // Ganti ke Huruf Besar Semua */
                keyword = keyword.toUpperCase();

                if (this.placeholder == 'Search Marital Status') {
                    if (keyword == 'MENIKAH') {
                        keyword = 1;
                    } else {
                        keyword = 0;
                    }
                }

                if (this.placeholder == 'Search Is Matched') {
                    if (keyword == 'YES' || keyword == 'YA') {
                        keyword = 1;
                    } else {
                        keyword = 0;
                    }
                }

                if (this.placeholder == 'Search Apply Status') {
                    keyword = '-';
                    if (keyword == 'APPLICATION INITIATED' || keyword == 'INITIATED' || keyword == 'APPLICATION') {
                        keyword = 0;
                    }
                    else if(keyword == 'SHORTLISTED') {
                        keyword = 1;
                    }
                    else if(keyword == 'INTERVIEW SCHEDULED' || keyword == 'INTERVIEW' || keyword == 'SCHEDULED') {
                        keyword = 2;
                    }
                    else if(keyword == 'INTERVIEW PASSED' || keyword == 'INTERVIEW' || keyword == 'PASSED') {
                        keyword = 3;
                    }
                    else if(keyword == 'INTERVIEW FAILED' || keyword == 'INTERVIEW' || keyword == 'FAILED') {
                        keyword = 4;
                    }
                    else if(keyword == 'JOB OFFERED' || keyword == 'JOB' || keyword == 'OFFERED') {
                        keyword = 5;
                    }
                    else if(keyword == 'OFFER DECLINED' || keyword == 'OFFER' || keyword == 'DECLINED') {
                        keyword = 6;
                    }
                    else if(keyword == 'REJECTED') {
                        keyword = 7;
                    }
                    else if(keyword == 'HIRED') {
                        keyword = 7;
                    }
                }

                if (keyword !== '' || keyword !== undefined) {
                    if (that.search() !== keyword) {
                        that
                            .search(keyword)
                            .draw();
                    }
                }

            }, 500));
        });
    };

    return {
        init: init,
        createVacancy: createVacancy,
        addRow: addRow,
        removeRow: removeRow,
        updateVacancy: updateVacancy,
        deleteVacancy: deleteVacancy
    };

})(commonModule);