var postModule = (function(commonModule) {

    var datatableBaseURL = commonModule.datatableBaseURL + 'post';

    var init = function() {
        _applyDatatable();
        _applyWYSIWYG();
        _applyDatepicker();
        $("#overlayForm").hide();
    };

    var _applyWYSIWYG = function() {

        $('#editor').summernote({
            minHeight: 300,
            cleaner:{
                    notTime:2400,
                    action:'both',
                    newline:'<br>',
                    keepHtml: false,
                    badTags: ['style','script','applet','embed','noframes','noscript', 'html'],
                    badAttributes: ['style','start']
            }
        });
    }

    var _applyDatepicker = function() {
        $('.datepicker').datepicker({
            weekStart: 1,
            todayHighlight: true,
            clearBtn: true,
            format: 'yyyy-mm-dd',
            autoclose: true
        });
    };

    var addRow = function() {
        var row_table = $("table#row_table");
        var template_row = $("#new_row").find('tbody');
        var new_row = template_row.clone();
        new_row = new_row.html();
        row_table.find('tbody').append(new_row);
    };

    var removeRow = function(me) {
        me.closest('tr').remove();
    };

    var createPost = function() {
        var callbackURL = $("input[name=callback_url]").val();

        /* Show Overlay */
        $("#overlayForm").show();

        /* Send Data */
        $.ajax({
            method: "POST",
            url: "/post",
            data: $('#main_form').serialize(),
            dataType: 'json',
            statusCode: {
                422: function(xhr) {
                    swal({
                        title: "Oops!",
                        text: 'Failed form validation. Please check your input.',
                        type: "error"
                    });
                    $("#overlayForm").hide();
                },
                500: function(xhr) {
                    swal({
                        title: "Oops!",
                        text: 'Something went wrong.',
                        type: "error"
                    });
                    $("#overlayForm").hide();
                }
            }
        }).done(function(response) {
            if (response.status == 1) {
                swal({
                    title: "Good!",
                    text: response.message,
                    type: "success",
                    timer: 2000
                }, function() {
                    if (callbackURL != '') {
                        window.location = "/" + callbackURL;
                    } else {
                        $("#main_form").trigger('reset');
                    }
                });
            } else {
                swal({
                    title: "Oops!",
                    text: response.message,
                    type: "error",
                    timer: 2000
                });
            }
            /* Hide Overlay */
            $("#overlayForm").hide();
        });
    };

    var updatePost = function() {
        var postID = $("input[name=post_id]").val();
        /* Show Overlay */
        $("#overlayForm").show();

        /* Send Data */
        $.ajax({
            method: "PUT",
            url: "/post/" + postID,
            data: $('#main_form').serialize(),
            dataType: 'json',
            statusCode: {
                422: function(xhr) {
                    swal({
                        title: "Oops!",
                        text: 'Failed form validation. Please check your input.',
                        type: "error"
                    });
                },
                500: function(xhr) {
                    swal({
                        title: "Oops!",
                        text: 'Something went wrong.',
                        type: "error"
                    });
                    $("#overlayForm").hide();
                }
            }
        }).done(function(response) {
            if (response.status == 1) {
                swal({
                    title: "Good!",
                    text: response.message,
                    type: "success",
                    timer: 2000
                }, function() {
                    //
                });
            } else {
                swal({
                    title: "Oops!",
                    text: response.message,
                    type: "error",
                    timer: 2000
                });
            }
            /* Hide Overlay */
            $("#overlayForm").hide();
        });
    };

    var deletePost = function(me) {

        swal({
                title: "Are you sure?",
                text: "You will not be able to recover this post !",
                type: "warning",
                showCancelButton: true,
                confirmButtonColor: "#DD6B55",
                confirmButtonText: "Yes, delete it!",
                closeOnConfirm: false
            },
            function(isConfirm) {
                var postID = me.data('record-id');
                if (isConfirm) {
                    $.ajax({
                        method: "POST",
                        url: "/post/" + postID,
                        dataType: 'json',
                        data: {
                            '_method': 'DELETE',
                            '_token': $('meta[name="csrf-token"]').attr('content')
                        },
                        statusCode: {
                            422: function(xhr) {
                                swal({
                                    title: "Oops!",
                                    text: 'Failed form validation. Please check your input.',
                                    type: "error"
                                });
                            },
                            500: function(xhr) {
                                swal({
                                    title: "Oops!",
                                    text: 'Failed form validation. Please check your input.',
                                    type: "error"
                                });
                            }
                        }
                    }).done(function(response) {
                        console.log(response);
                        if (response.status == 1) {
                            swal({
                                title: "Good!",
                                text: response.message,
                                type: "success",
                                timer: 2000
                            }, function() {
                                me.closest('tr').remove();
                                $('#datatable').DataTable().ajax.reload(null, false);
                            });
                        } else {
                            swal({
                                title: "Oops!",
                                text: response.message,
                                type: "error",
                                timer: 2000
                            });
                        }
                    }).fail(function(response) {
                        swal({
                            title: "Oops!",
                            text: response.message,
                            type: "error",
                            timer: 2000
                        });
                    });
                }
            });


    };

    var _applyDatatable = function() {
        /* Tambah Input Field di TFOOT */
        $('#datatable tfoot th').each(function() {
            var title = $(this).text();
            if (title !== '') {
                $(this).html('<input type="text" class="form-control" placeholder="Search ' + title + '" style="width: 100%;" />');
            }
            if (title == 'Created Date') {
                $(this).html('<input type="text" class="datepicker form-control" placeholder="Search ' + title + '" style="width: 100%;" />');
            }
            if (title == 'Updated Date') {
                $(this).html('<input type="text" class="datepicker form-control" placeholder="Search ' + title + '" style="width: 100%;" />');
            }
        });

        var table = $('#datatable').DataTable({
            processing: true,
            serverSide: true,
            ajax: {
                "url": datatableBaseURL,
                "type": "POST"
            },
            language: {
                "decimal": ",",
                "thousands": "."
            },
            order: [
                [4, "desc"]
            ],
            columns: [{
                data: 'action',
                name: 'action',
                orderable: false,
                searchable: false
            }, {
                data: 'post_category_id',
                name: 'post_category_id'
            }, {
                data: 'title',
                name: 'title'
            }, {
                data: 'is_published',
                name: 'is_published'
            }, {
                data: 'created_at',
                name: 'created_at'
            }, {
                data: 'updated_at',
                name: 'updated_at'
            }]
        });

        /* Ketika Value pada Input di TFOOT berubah, Maka Search Sesuai Kolom */
        table.columns().every(function() {
            var that = this;
            $('input', this.footer()).on('keyup change', _.debounce(function() {
                var keyword = this.value;

                // Ganti ke Huruf Besar Semua */
                keyword = keyword.toUpperCase();

                if (this.placeholder == 'Search Is Published') {
                    keyword = keyword.toUpperCase();
                    if (keyword == 'TRUE' || keyword == 'YA' || keyword == 'YES' || keyword == 'Y' || keyword == '1') {
                        keyword = 1;
                    } else {
                        keyword = 0;
                    }
                }

                if (this.placeholder == 'Search Category') {
                    $.ajax({
                        method: "GET",
                        url: "/api/post-category?name-lk=" + keyword,
                        dataType: 'json',
                    }).done(function(response) {
                        if (response.status == 1) {
                            keyword = response.id;
                        } else {
                            keyword = 0;
                        }

                        if (that.search() !== keyword) {
                            that
                                .search(keyword)
                                .draw();
                        }
                    });
                };

                if (keyword !== '' || keyword !== undefined) {
                    if (that.search() !== keyword) {
                        that
                            .search(keyword)
                            .draw();
                    }
                }
            }, 500));
        });
    };

    return {
        init: init,
        addRow: addRow,
        removeRow: removeRow,
        createPost: createPost,
        updatePost: updatePost,
        deletePost: deletePost
    };

})(commonModule);